@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  background-color: #161616;
  margin: 0;
  font-family: "FaktumRegular", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* ::-webkit-scrollbar {
  width: 20px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: #a8bbbf;
}
::-webkit-scrollbar-thumb {
  background-color: #a8bbbf;
  border-radius: 20px;
}
::-webkit-scrollbar-thumb {
  background-color: #a8bbbf;
  border-radius: 20px;
  border: 3px solid transparent;
  background-clip: content-box;
}
::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
  background-color: #838a8b;
} */

/*
Font Weight	Numerical Weight
"Thin", "Hairline"	100
"Extra Light", "Ultra Light"	200
"Light", "Book"	300
"Normal", "Regular", "Roman", "Standard", "Plain"	400/Normal
"Medium", "Demi"	500
"Semi Bold", "Semi Bld", "Demi Bold", "Demi Bld"	600
"Bold", "Bld"	700/Bold
"Extra Bold", "Ultra Bold", "Extra Bld", "Ultra Bld"	800
"Black", "Heavy", "Ultra", "Fat", "Poster", "Ultra Black" 	 900
*/

@layer base {
  @font-face {
    font-family: "AfricaVibesRegular";
    font-weight: 400;
    src: local("AfricaVibesRegular"),
      url(./assets/fonts/AfricaVibes-Regular.ttf) format("truetype");
  }
  @font-face {
    font-family: "FaktumRegular";
    font-weight: 400;
    src: local("FaktumRegular"),
      url(./assets/fonts/Faktum-Regular.otf) format("opentype");
  }
  @font-face {
    font-family: "FaktumBold";
    font-weight: 700;
    src: local("FaktumBold"),
      url(./assets/fonts/Faktum-Bold.otf) format("opentype");
  }
  @font-face {
    font-family: "CorinthiaRegular";
    font-weight: 400;
    src: local("CorinthiaRegular"),
      url(./assets/fonts/Corinthia-Regular.ttf) format("truetype");
  }
}

.shadow-box {
  box-shadow: 2px 2px 50px 1px rgba(185, 182, 182, 0.45);
}
.shadow-box-2 {
  box-shadow: 2px 10px 28px rgba(75, 0, 129, 0.12);
}

.spinning {
  animation-name: spinning;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes spinning {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Shadow CSS */
.shadow-css {
  box-shadow: 0px 0px 10px 5px #3AAA35;
}

/* Transition CSS */
.button-transition-css {
  /* font-size: 2em; */
  /* padding: 2%; */
  transition: padding 1s, font-size 1s;
  /* background-image: linear-gradient(322.24deg, #60D9FF -4.38%, #1D74FF 48.84%); */
  /* box-shadow: 0px 0px 20px rgba(71, 184, 255, 0.5), 0px 5px 5px -1px rgba(58, 125, 233, 0.245848), inset 4px 4px 8px rgba(175, 230, 255, 0.521689), inset -4px -4px 8px rgba(19, 95, 216, 0.356998); */
}
.button-transition-css:hover {
  font-size: 25px;
  /* padding: 4%; */
  /* background-image: linear-gradient(126.58deg, #60D9FF -3.24%, #1D74FF 57.16%); */
  /* box-shadow: 0px 5px 50px rgba(71, 184, 255, 0.75), 0px 10px 10px -4px rgba(58, 125, 233, 0.360113), inset 4px 4px 8px rgba(175, 230, 255, 0.521689), inset -4px -4px 8px rgba(19, 95, 216, 0.356998); */
}

/* Transition CSS */
.transition-live-css {
  /* font-size: 2em; */
  padding-left: 4%;
  padding-right: 4%;
  padding-top: 0.1%;
  padding-bottom: 0.1%;
  transition: padding 1s, font-size 1s;
  /* background-image: linear-gradient(322.24deg, #60D9FF -4.38%, #1D74FF 48.84%); */
  /* box-shadow: 0px 0px 20px rgba(71, 184, 255, 0.5), 0px 5px 5px -1px rgba(58, 125, 233, 0.245848), inset 4px 4px 8px rgba(175, 230, 255, 0.521689), inset -4px -4px 8px rgba(19, 95, 216, 0.356998); */
}
.transition-live-css:hover {
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 0.3%;
  padding-bottom: 0.3%;
  /* background-image: linear-gradient(126.58deg, #60D9FF -3.24%, #1D74FF 57.16%); */
  /* box-shadow: 0px 5px 50px rgba(71, 184, 255, 0.75), 0px 10px 10px -4px rgba(58, 125, 233, 0.360113), inset 4px 4px 8px rgba(175, 230, 255, 0.521689), inset -4px -4px 8px rgba(19, 95, 216, 0.356998); */
}